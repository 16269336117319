import axios from "axios";

export default {
  async getCommesse() {
    const data = new FormData();
    data.append("orderby", "projects_creation_date");
    data.append("orderdir", "desc");

    const response = await axios.post("rest/v1/search/projects", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data.data);
    return response.data;
  },
  async getClienteCommesse(id: string) {
    const data = new FormData();
    data.append("where[projects_customer_id]", id.toString());

    const response = await axios.post("rest/v1/search/projects", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data.data);
    return response.data;
  },

  async getCommessaDetail(id: string) {
    const data = new FormData();
    data.append("where[projects_id]", id.toString());

    const response = await axios.post("/search/projects", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data.data[0];
  },

  async getCommessaLibretto(id: string) {
    const data = new FormData();
    data.append("where[libretto_impianto_impianto]", id.toString());

    const response = await axios.post("/search/libretto_impianto", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data.data);
    return response.data.data;
  },

  async getMaterialeCommessa(id: string) {
    const data = new FormData();
    data.append("where[projects_billing_items_project]", id.toString());

    const response = await axios.post("/search/projects_billing_items", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data);
    return response.data.data;
  },

  async getInterventiCommessa(commessaId: string) {
    const data = new FormData();
    data.append("orderby", "tickets_reports_date");
    data.append("orderdir", "desc");
    data.append("where[tickets_reports_project_id]", commessaId.toString());

    const response = await axios.post("/search/tickets_reports", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response.data);
    return response.data.data;
  },

  async getCommessaAttachments(commessaId: string) {
    const data = new FormData();
    /* data.append("orderby", "tickets_reports_date");
    data.append("orderdir", "desc"); */
    data.append("where[project_attachments_project_id]", commessaId);
    data.append("where[project_attachments_type_mostra_su_app]", '1');

    const response = await axios.post("rest/v1/search/project_attachments", data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    // console.log(response);
    return response;
  },
};
